.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 100px #7ac142;
    }
  }

.container_Error {
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 10px rgba(#000, 0.05);
  width: 100%;
  height: 70px;
  border-radius: 50%;
}

.circle_Error,
.circle-border_Error {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.circle_Error {
  z-index: 1;
  position: relative;
  /* background: white; */
  background: #ff8866;	
  transform: scale(1);
  animation: error-anim 700ms ease;
}

.circle-border_Error {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: circle-error-anim 400ms ease;
  background: #ff8866;	
}

@keyframes error-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes circle-error-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}

.error::before,
.error::after {
  content: "";
  display: block;
  height: 5px;
  background: white;
  position: absolute;
}

.error::before {
  width: 40px;
  top: 48%;
  left: 22%;
  transform: rotateZ(50deg);
}

.error::after {
  width: 40px;
  top: 48%;
  left: 22%;
  transform: rotateZ(-50deg);
}