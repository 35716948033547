.inner button {
    background-color: #FFCC66 !important;
}

.inner button:hover{
    background-color: #222 !important;
}

.Header{
    color: white !important;
    background-color: black !important;
}

html,body { 
    height: 100%;
    margin: 0px; 
    padding-top: 30px;
}