  .contenedor {
    display: relative;
    width: 90%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .dvContenido {
    border-radius: 5px;
    /* background-color: rgb(145, 145, 145);
    border: 2px solid rgb(80, 80, 80);  */
    /* background-color: (10deg, #807f7f, #d2cfcf); */
    margin: 0px auto;
    /* padding: 4%; */
    display: inline-block;
  }

  .contenedor img {
    cursor: pointer;
  }

  .contenedor h3 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: white;
  }

  .contenedor h2 {
    color: white;
  }

  .row {
    padding: 5px;
  }

  .contenedor h4 {
    color: rgb(0, 0, 0);
    /* border-color: rgb(225, 154, 12); */
    border-radius: 20px;
    font-size: 14px;
    /* background-color: rgb(255, 204, 102); */
    background: #FFCC66;
    text-align: center;
    text-decoration: none;
    cursor: 'pointer';
    font-weight: 400;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 15px 40px;
    width: 250px;
    margin: auto;
  }

  .contenedor h4:hover {
    color: #FFFFFF;
    background: #222222;
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  }

  .container {
    padding: 4%;
    background-color: black;
  }

  .encabezadoFooter {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .txtFooter {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .txtFooter a {
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
  }

  .txtFooter a:hover {
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 600;
  }

  .boton {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: white;
    /* padding:5px 0 5px 20px; */
    background-image: url(../assets/img/svgEmail.svg);
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: 12px 15px;
    background-color: #FFCC66;
    margin: 0 auto;
    border-top-right-radius: 5px;
    border-end-end-radius: 5px;
    opacity: 1;
  }

  .parrafo {
    text-align: justify;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 17px;
    font-weight: lighter;
    line-height: 1.5;
  }

  .contenedor .form-control {
    height: 50px;
    font-size: initial;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #CDD9ED;

    &::placeholder {
      color: #90b2f2;
    }
  }

  @media (max-width: 992px) {
    .encabezadoFooter {
      text-align: center;
      margin-top: 30px;
    }

    .txtFooter {
      text-align: center;
    }

    .input-group {
      margin-top: 30px;
    }

  }

  .sociales {
    margin: 0 auto;

  }

  .sociales a {
    text-decoration: none;
    cursor: pointer;
  }

  .input-group {
    position: relative;
    display: inline-flex;
  }

  .gridMasVendido {
    /* display: grid; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    gap: 0;
    /* padding-top: 20px; */
    transform-origin: 0 0;

    justify-items: center
  }

  .gridMasVendido .item {

    flex: 1 0 100%;
    text-align: center;
    padding: 10px;
    position: relative;
    overflow: hidden;
    /* height: 200px; */
    height: auto;
    margin: auto;
    margin-top: 1em;
    border-radius: 10px;

    @media screen and (min-width: 576px) {
      flex-basis: 50%;
    }
  
    @media screen and (min-width: 768px) {
      flex-basis: 33.333%;
    }
  
    @media screen and (min-width: 1000px) {
      flex-basis: 25%;
    }

    @media screen and (min-width: 1400PX) {
      flex-basis: 20%;
    }
  }

  .gridMasVendido .item img {
    /* width: 101%; */
    /* margin-top: -60px; */
    /* transform: skewY(5deg) scale(1.2,1.2); */
    transition: all 0.75s;
    border-radius: 10px;
    /* width: 250px;  */
  }

  .gridMasVendido .item:hover img {
    transform: scale(1.2, 1.2);
  }

  .gridMasVendido .item .bg-hover {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    display: grid;
    position: absolute;
    z-index: 2;
    top: 0%;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* transform: skewY(5deg) scale(1,1); */
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .gridMasVendido .item:hover .bg-hover {
    overflow: hidden;
    opacity: 1;
  }

  .gridMasVendido .item:hover .bg-hover .info {
    animation-name: bounce;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
  }

  .gridMasVendido .item .bg-hover .title {
    color: rgb(241, 233, 0);
    font-weight: 600;
    font-size: 20px;
  }

  .btn {
    border-radius: 5px;
    background: #FFCC66;
    text-align: center;
    text-decoration: none;
    cursor: 'pointer';
    box-sizing: border-box;
    width: 30px;
    margin: auto;
  }

  .item .bg-hover {
    background-color: rgba(0, 0, 0, 0.7);
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    display: grid;
    position: absolute;
    z-index: 2;
    top: 0%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* transform: skewY(5deg) scale(1,1); */
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .item:hover .bg-hover {
    overflow: hidden;
    opacity: 1;
  }

  @media (max-width:540px) {
    /* .gridMasVendido .item{
      height: 200px;
  } */

    .gridMasVendido {
      /* display: grid; */
      grid-template-columns: 1fr 1fr 1fr;
      /* gap: 0 0; */
    }
  }

  @media (max-width:800px) {
    /* .gridMasVendido .item{
      height: 150px;
  } */
  }

  @media (max-width:900px) {
    /* .gridMasVendido .item{
      height: 250px;
  } */

    .gridMasVendido {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width:1450px) {
    .gridMasVendido .item {
      padding: 0;
      position: relative;
      overflow: hidden;
    }
  }

  @media (max-width:1280px) {
    /* .gridMasVendido .item{
      height: 150px;
  } */
  }

  .artista-card {
    border: 2px solid #fff;
    border-radius: 10px;
    background-color: #eee !important;
    align-items: center;
    text-align: left;
    height: 130px;
    display: flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .artista-card h3 {
    color: black;
  }

  .artista-card:hover {
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  }

  .artista-card img {
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  