@font-face {
    font-family: "Lucida Grande", sans-serif;
    src: local("Lucida Grande") url(/src/assets/fonts/lucida-grande/LUCIDAG.TTF) format('TTF');
    font-weight: normal;
}

@font-face {
    font-family: "Lucida Grande", sans-serif;
    src: local("Lucida Grande") url(/src/assets/fonts/lucida-grande/LUCIDAGB.TTF) format("TTF");
    font-weight: bold;
}

* {
    font-family: "Lucida Grande", sans-serif !important;
}