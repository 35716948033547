/* table {
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    min-height: 0.1%;
}

td {
    line-height: 1.42857143;
    padding: 12px 8px;
    vertical-align: middle;
    line-height: 1.5em;
    padding: 12px 8px!important;
    font-size: 0.875rem;
    border-bottom: none;
    border-top: 1px solid;
    position: relative;
}*/
.inputfile {
  width: 0.1px;
	height: 0.1px;
opacity: 0;
overflow: hidden;
position: absolute;
z-index: -1;

}

.inputfile + label {
padding: 5px;
font-size: 14px;
color:  rgba(0, 0, 0, 0.65);
background-color: white;
display: inline-block;
border: solid 1px;
border-color: gray;
border-radius: 5px;

}

.inputfile:focus + label,
.inputfile + label:hover {
color: #40a9ff;
border-color: #40a9ff;
}
.inputfile + label {
cursor: pointer; /* "hand" cursor */
}

table {
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
}

th {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.5em;
    color: #555;
}

th {
    font-size: 1.063rem;
    border-bottom-width: 1px;
    font-weight: 300;
    border-top-width: 0 !important;
    border-top: 1px solid #ddd;
    color: #3C4858;
}



.scrollTablaConfiguracionProducto {
    display: block;
    max-height: 500px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollTablaSeleccionProducto {
  display: block;
  max-height: 450px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
}

.table-wrapper-scroll-y {
  display: block;
  max-height: 400px;
  max-width: 600px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ContenedorImagen {
    position: relative;
    width: 100%;
    max-width: 400px;
  }
  
  .ContenedorImagen img {
    width: 100%;
    height: auto;
  }
  
  .ContenedorImagen .btnImagen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f44336;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
  }
  
  .ContenedorImagen .btnImagen:hover {
    background-color: black;
  }



  .ContenedorImagenListaDeseos {
    position: relative;
    width: 100%;
    max-width: 400px;
  }
  
  .ContenedorImagenListaDeseos img {
    width: 100%;
    height: auto;
  }
  
  .ContenedorImagenListaDeseos .btnImagen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: black;
    color: white;
    font-size: 14px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
   
  }
  
  .ContenedorImagenListaDeseos .btnImagen:hover {
    background-color: black;
  }


  /*--------------------------------------------*/
 
  
 
  
  .ContenedorImagenPerfil .btnImagen {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: black;
    color: white;
    font-size: 12px;
    /* padding: 12px 24px; */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    /* width: 10px; */
   
  }
  
  .ContenedorImagenPerfil .btnImagen:hover {
    background-color: black;
  }
  /*--------------------------------------------*/

    

  .ContenedorImagenLogo .btnImagen {
    position: absolute;
    bottom: 0%;
    left: 5%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: black;
    color: white;
    font-size: 12px;
    /* padding: 12px 24px; */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    /* width: 10px; */
   
  }
  
  .ContenedorImagenLogo .btnImagen:hover {
    background-color: black;
  }

  /*--------------------------------------------*/


  .TextDanger{
    color: #f44336;
  }

   .botonMasMenos{    
    font-size: 0.6875rem;
    line-height: 1.5;
    border:none;
    border-radius: 0.2rem;
    color: #FFF;
    box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14), 0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
    background-color: #333;
    width:30px;
    height:30px;
  }

  .botonCantidad{
    font-size: 0.6875rem;
    line-height: 1.5;
    border:none;
    border-radius: 0.2rem;
    color: #FFF;
    box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
    background-color: #f44336;
    width:30px;
    height:30px;
  }
  






  